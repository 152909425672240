import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import media  from 'src/assets/media.json';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {
  mediapage:any = [];
  
  constructor(private router: Router, private metaService: Meta, private title: Title) { }

  ngOnInit(): void {
    this.loadData();
  }
  loadData() {
    this.mediapage = media;
    this.updateMetaTags()
  }
  onClick(mediaTitle: string){
    this.router.navigate(['/mediadetail', mediaTitle])
  }

  updateMetaTags() {
    this.title.setTitle('Media  | Indotambang');
    this.metaService.updateTag({
      name: 'title', content: String('Media  | Indotambang')
    });
    this.metaService.updateTag({});
    this.metaService.updateTag({
      property: 'og:title',
      content: String('Media  | Indotambang')
    });
    this.metaService.updateTag({name:'title', content:this.mediapage[0].title});
    this.metaService.updateTag({name:'description', content:this.mediapage[0].content});

    this.metaService.updateTag({property:'og:title', content:this.mediapage[0].title});
    this.metaService.updateTag({property:'og:description', content:this.mediapage[0].content});
    this.metaService.updateTag({property:'og:image', content:this.mediapage[0].img})
  }
}
