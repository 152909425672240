<nav class="fixed top-0 right-0 z-50 overflow-y-auto w-full">
    <div class="nav-button fixed z-30 cursor-pointer text-3xl py-2" (click)="toogleNav()">
        <div class="flex pointer-events-auto items-center">
            <img class="absolute top-0 left-0 max-w-md pointer-events-none" routerLink="/" src="/assets/images/nav.svg" alt="">
            <div class="bg-primary flex-wrap z-10 pl-7 pr-6  rounded-tr-full rounded-br-full"><fa-icon
                    class="fa-xs text-secondary" *ngIf="!showNav" [icon]="faBars"></fa-icon></div>
            <div class=" z-10">
                <fa-icon class="text-secondary items-start" *ngIf="showNav" [icon]="faXmark"></fa-icon>
            </div>
            <div class="z-10 pointer-events-auto">
                <img class="" src="assets/images/logo.webp" alt="">
            </div>
        </div>
    </div>
    <div [ngClass]="showNav  ? '-translate-x-0' : '-translate-x-full'" class="fixed z-10 h-screen w-full transform overflow-y-auto bg-primary/90 transition-all duration-300 ease-in-out">
        <div class="" >
            <ul class="mx-auto max-w-2xl text-center mt-36 lg:mt-12 text-xl font-Merienda text-white">
                <li class="cursor-pointer border-b w-fit mx-auto border-white mb-6 hover:text-secondary" [routerLink]="['/'] "   (click)="scroll('home');showNav=false"><a>Home</a></li>
                <li class="cursor-pointer border-b w-fit mx-auto border-white mb-6 hover:text-secondary" [routerLink]="['/']" (click)="scroll('tentangKami');showNav=false"><a>Tentang Kami</a></li>
                <li class="cursor-pointer border-b w-fit mx-auto border-white mb-6 hover:text-secondary" [routerLink]="['/']" (click)="scroll('visMisi');showNav=false"><a>Visi & Misi</a></li>
                <li class="cursor-pointer border-b w-fit mx-auto border-white hover:text-secondary" [routerLink]="['/']" (click)="scroll('media');showNav=false"><a>Media</a></li>
            </ul>
        </div>
    </div>
</nav>