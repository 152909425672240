<section class="mx-auto max-w-6xl my-20 px-4 lg:px-0">
    <div>
        <h2 class="text-center font-Merienda text-base font-medium text-primary">Media</h2>
        <h3 class="text-lg font-Poppins font-bold text-center py-2 ">Semua Media & Informasi</h3>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-16" >
                <div  *ngFor="let news of mediapage">
                    <div routerlink="/mediadetail" class="relative cursor-pointer" (click)="onClick(news.slug)">
                        <div>
                            <img class="relative w-full lg:w-72 h-96 object-cover bg-black" src="{{news.img}}" alt="">
        
                        </div>
                        <div class="absolute top-0  w-full h-full  bg-black/60 px-4 py-5">
                            <div class="text-sm text-right text-white ">{{news.date}}</div>
                            <div class="text-sm absolute bottom-20 text-slate-50 font-Poppins">{{news.title}}</div>
                        </div>
                        <svg class="absolute bottom-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1400 300">
                            <path fill="#fff" fill-opacity="1"
                                d="M0,128L80,144C160,160,320,192,480,186.7C640,181,800,139,960,112C1120,85,1280,75,1360,69.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z">
                            </path>
                        </svg>
                    </div>
                </div>
        </div>
    </div>
</section>