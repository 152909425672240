<section id="home">
    <picture>
        <img class="relative w-full h-screen object-cover" src="assets/images/banner/banner.webp" alt="">
        <p
            class="absolute top-2/3 lg:top-1/3 right-0 text-white w-full lg:w-2/4 font-Poppins text-3xl lg:text-4xl font-semibold px-4 lg:px-16 leading-loose">
            We exist to improve life and transform the future <span
                class="text-5xl text-white font-bold font-Merienda">Together</span></p>
    </picture>
</section>
<section id="tentangKami" class="mx-auto max-w-6xl px-4 lg:px-0 my-9 py-0 lg:my-20 lg:py-11">
    <div class="grid grid-cols-12 gap-x-6">
        <div class="col-span-12 lg:col-span-6 ">
            <picture class="relative">
                <img class="relative mx-auto" src="assets/images/about.webp" alt="">
                <svg class="absolute bottom-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 290">
                    <path fill="#fff" fill-opacity="1"
                        d="M0,256L60,261.3C120,267,240,277,360,282.7C480,288,600,288,720,261.3C840,235,960,181,1080,181.3C1200,181,1320,235,1380,261.3L1440,288L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z">
                    </path>
                </svg>
            </picture>
        </div>
        <div class="col-span-12 mt-5 lg:mt-0 lg:col-span-6">
            <h2 class="text-2xl lg:text-3xl text-center lg:text-start font-Merienda text-primary">Tentang Kami</h2>
            <div class="text-sm font-Poppins text-justify text-gray-900">
                <p class="mt-4">PT IBI merupakan perusahaan yang bergerak dibidang
                    perdagangan hasil tambang (Coal dan mineral), Dan Exportir.</p>
                <p class="my-2 lg:my-4">Sebagai trading hasil pertambangan, khususnya Batubara kami
                    telah menjalin kerjasama penjualan (untuk Lokal dan Export)
                    dengan banyak Perusahaan Tambang batubara baik itu yang
                    berizin IUP-OP maupun PKP2B dimana perusahaan tambang
                    tersebut sudah memiliki izin Clean and Clear maupun Eksportir
                    Terdaftar.
                </p>
                <p>Sebagai Eksportir, kami fokus pada penjualan ke konsumen asing,
                    terutama negara - negara Asia. Kami ingin memuaskan pelanggan
                    kami dengan menjamin pasokan dengan tingkat kualitas terjaga,
                    tepat waktu dan berkelanjutan</p>
                <!-- <button
                    class="text-xs mt-7 hover:bg-secondary/90 font-Merienda px-5 py-1 bg-secondary rounded-full border border-black">READ
                    MORE</button> -->
            </div>
        </div>
    </div>
</section>

<!--Visi -->
<section id="visMisi" class="mx-auto max-w-6xl px-4 lg:px-0 my-9 md:my-16 py-0 lg:my-20 lg:py-11">
    <div>
        <h2 class="text-center text-2xl lg:text-3xl font-Merienda font-medium text-primary">Tujuan Perusahaan</h2>
        <h3 class="text-lg font-Poppins font-bold text-center pt-2 pb-0 lg:pb-5">Memberikan Kontribusi Positif Bagi
            Masyarakat
        </h3>
        <div>
            <!-- <div class="rounded-full px-3 py-2 text-base max-w-[100px] text-center mx-auto font-Poppins ">
                <div class=" bg-secondary border border-black rounded-full">Visi</div>
                
            </div> -->
            <div class="grid grid-cols-12 gap-x-5 pt-4 lg:pt-20">
                <div class="col-span-12 order-2 lg:order-1 lg:col-span-6 place-self-center">
                    <h5 class="pt-2 pb-2 font-Poppins text-lg font-medium">Visi PT IBI</h5>
                    <p class="font-Poppins text-sm text-gray-900 text-justify"> Menjadi perusahaan trading batubara yang
                        memiliki integritas dan kualitas. Kami ingin memuaskan
                        pelanggan kami dengan menjamin pasokan dengan tingkat
                        kualitas terjaga, ketepatan waktu suplai dan berkelanjutan.</p>
                    <h5 class="pt-2 pb-2 font-Poppins text-lg font-medium">Vission of PT IBI</h5>
                    <p class="font-Poppins text-sm text-gray-900 text-justify"> Being a leading coal trading company
                        that has integrity
                        and quality. We want to satisfy our customers by guaranteeing
                        quality, timely supply and sustainable.</p>
                    <!-- <button
                        class="text-xs flex ml-auto mt-7 hover:bg-secondary/90 font-Merienda px-5 py-1 bg-secondary rounded-full border border-black">READ
                        MORE</button> -->
                </div>
                <div class="col-span-12 pt-4 order-1 lg:order-2 lg:pt-0 lg:col-span-6">
                    <picture>
                        <img class="h-64 mx-auto lg:h-96 rounded-2xl" src="assets/images/visi.webp" alt="">
                    </picture>
                </div>

            </div>
        </div>
        <div>
            <!-- <div class="rounded-full px-3 py-2 text-base max-w-[100px] text-center mx-auto font-Poppins ">
                <div class=" bg-secondary border border-black rounded-full">Misi</div>
                
            </div> -->
            <div class="grid grid-cols-12 gap-x-5 pt-4 lg:pt-20">
                <div class="col-span-12 lg:col-span-6">
                    <picture>
                        <img class="h-64 lg:h-96 mx-auto rounded-2xl" src="assets/images/misi.webp" alt="">
                    </picture>
                </div>
                <div class="col-span-12 lg:col-span-6 place-self-center">
                    <h5 class="pt-2 pb-2 font-Poppins text-lg font-medium">Misi PT IBI</h5>
                    <p class="font-Poppins text-sm text-gray-900 text-justify">Melaksanakan tanggung jawab dalam proses
                        pengiriman batubara dari tambang hingga akhir transaksi
                        batubara.</p>
                    <p class="font-Poppins text-sm text-gray-900 text-justify mt-3">
                        Menjaga kualitas batubara hingga ke konsumen.
                    </p>
                    <h5 class="pt-2 pb-2 font-Poppins text-lg font-medium">Mission of PT IBI</h5>
                    <p class="font-Poppins text-sm text-gray-900 text-justify"> Carry out responsibilities in the coal
                        delivery
                        process from the mine to the coal transaction.</p>
                    <p class="font-Poppins text-sm text-gray-900 text-justify mt-3">
                        Maintain coal qualitiy to consumers.
                    </p>

                    <!-- <button
                        class="text-xs flex ml-auto mt-7 hover:bg-secondary/90 font-Merienda px-5 py-1 bg-secondary rounded-full border border-black">READ
                        MORE</button> -->
                </div>


            </div>
        </div>
    </div>
</section>

<!-- Media -->
<section id="media" class="mx-auto max-w-6xl px-4 lg:px-0 my-9 py-0 lg:my-20 lg:py-11">
    <div>
        <h2 class="text-center font-Merienda text-2xl lg:text-3xlfont-medium text-primary">Media</h2>
        <h3 class="text-lg font-Poppins font-bold text-center py-2 ">Our Latest News Post</h3>
        <button type="button"
            class="text-xs flex ml-auto mt-2 lg:mt-7 hover:bg-secondary/90 font-Merienda px-5 py-1 bg-secondary rounded-full border border-black"><a
                routerLink="/media">Lihat
                Semua</a></button>
        <div class="hidden lg:block">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-6 lg:mt-6">
                <div *ngFor="let news of mediapage.slice(0,4)">
                    <div class="relative cursor-pointer" routerLink="/mediadetail" class="relative cursor-pointer"
                        (click)="onClick(news.slug)">
                        <div>
                            <img class="relative w-full lg:w-72 h-96 object-cover bg-black" src="{{news.img}}" alt="">
                        </div>
                        <div class="absolute top-0  w-full h-full  bg-black/60 px-4 py-5">
                            <div class="text-sm text-right text-white ">{{news.date}}</div>
                            <div class="text-sm absolute bottom-20 text-slate-50 font-Poppins">{{news.title}}</div>
                        </div>
                        <svg class="absolute bottom-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1400 300">
                            <path fill="#fff" fill-opacity="1"
                                d="M0,128L80,144C160,160,320,192,480,186.7C640,181,800,139,960,112C1120,85,1280,75,1360,69.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z">
                            </path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <!-- media mobile -->
        <div class="lg:hidden mt-7">
            <div class="grid grid-cols-1 md:grid-cols-2  gap-4 mt-6 lg:mt-6">
                <div *ngFor="let news of mediapage.slice(0,3)">
                    <div class="relative cursor-pointer" routerLink="/mediadetail" class="relative cursor-pointer"
                        (click)="onClick(news.slug)">
                        <div>
                            <img class="relative w-full lg:w-72 h-96 object-cover bg-black" src="{{news.img}}" alt="">
                        </div>
                        <div class="absolute top-0  w-full h-full  bg-black/60 px-4 py-5">
                            <div class="text-sm text-right text-white ">{{news.date}}</div>
                            <div class="text-sm absolute bottom-20 text-slate-50 font-Poppins">{{news.title}}</div>
                        </div>
                        <svg class="absolute bottom-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1400 300">
                            <path fill="#fff" fill-opacity="1"
                                d="M0,128L80,144C160,160,320,192,480,186.7C640,181,800,139,960,112C1120,85,1280,75,1360,69.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z">
                            </path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>