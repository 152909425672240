import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import media from 'src/assets/media.json';
import SwiperCore, { FreeMode } from "swiper"
import { SwiperComponent } from "swiper/angular"

SwiperCore.use([FreeMode]);
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  faArrowRightLong = faArrowRightLong;
  mediapage:any = [];

  constructor(private router: Router, private metaService: Meta, private title: Title) { }

  ngOnInit(): void {
    this.loadData()
  }
  loadData() {
    this.mediapage = media;
  }
  onClick(mediaTitle: string){
    this.router.navigate(['/mediadetail', mediaTitle])
  }

  updateMetaTags() {
    this.title.setTitle('Indotambang');
    this.metaService.updateTag({
      name: 'title', content: String('Indotambang')
    });
    this.metaService.updateTag({});
    this.metaService.updateTag({
      property: 'og:title',
      content: String('Indotambang')
    })
  }

}
