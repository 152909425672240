import { Component, OnInit } from '@angular/core';
import { faInstagram, faFacebookF, faTwitter} from '@fortawesome/free-brands-svg-icons';
import { faCopyright} from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  faInstagram = faInstagram;
  faCopyright = faCopyright;
  faFacebookF = faFacebookF;
  faTwitter = faTwitter;
  faPhone = faPhone;

  constructor() { }

  ngOnInit(): void {
  }

}
