import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  faBars = faBars;
  faXmark = faXmark;
  showNav: boolean =false

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  toogleNav() {
    this.showNav = !this.showNav;
  }
  scroll(elementId: string) {
    this.viewportScroller.scrollToAnchor(elementId);
  }

}
