<div class="mx-auto max-w-4xl container px-4 lg:px-0 mt-40 lg:mt-32">
    <div class="w-full lg:w-4/5 mx-auto">
        <h1 class="text-2xl lg:text-3xl font-Poppins text-center font-semibold">{{mediaPage.title}}</h1>
        <div class="text-lg font-medium mt-7 font-Poppins">{{mediaPage.date}}</div>
    </div>
    <div class="py-7 lg:py-10">
        <picture>
            <img class="rounded-sm w-full" src={{mediaPage.img}} alt="">
        </picture>
        <div class="pt-10">
            <p class="text-base text-gray-900 leading-snug text-justify font-Poppins" [innerHTML]="mediaPage.content"></p>
        </div>
    </div>
</div>