<div>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 180"><path fill="#990000" fill-opacity="1" d="M0,96L60,90.7C120,85,240,75,360,74.7C480,75,600,85,720,106.7C840,128,960,160,1080,165.3C1200,171,1320,149,1380,138.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
        
        <div class="bg-primary">
            <div class="mx-auto max-w-2xl p-4 grid grid-cols-12 gap-4 lg:gap-0">
                <div class="col-span-12 mx-auto lg:mx-0 md:col-span-4 ">
                    <h1 class="text-lg font-bold uppercase text-white font-">integrity</h1>
                    <div class="flex space-x-3 mt-3">
                        <div class="border cursor-pointer border-white w-7 h-7 text-center rounded-full hover:border-secondary">
                            <a href=""><fa-icon class="fa-md text-center text-white" [icon]="faInstagram"></fa-icon></a>
                            
                        </div>
                        <div class="border cursor-pointer border-white w-7 h-7 text-center rounded-full hover:border-secondary">
                            <a href=""><fa-icon class="fa-md text-white" [icon]="faFacebookF"></fa-icon></a>
                            
                        </div>
                        <div class="border cursor-pointer border-white w-7 h-7 text-center rounded-full hover:border-secondary">
                            <a class="" href=""><fa-icon class="fa-md text-white" [icon]="faTwitter"></fa-icon></a>
                        </div>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 mx-auto">
                    <h2 class="text-white text-base font-semibold font-Poppins border-b-2 w-fit border-secondary">Links</h2>
                    <ul class="text-sm text-slate-50 mt-2 lg:mt-3 font-Poppins">
                        <li>
                            <a class="hover:text-secondary" routerLink="/">Home</a>
                        </li>
                        <li>
                            <a class="hover:text-secondary" routerLink="/tentangkami">Tentang Kami</a>
                        </li>
                        <li>
                            <a class="hover:text-secondary" routerLink="/visimisi">Visi & Misi</a>
                        </li>
                        <li>
                            <a class="hover:text-secondary" routerlink="/media">Media</a>
                        </li>
                    </ul>
                </div>
                <div class="col-span-6 md:col-span-4 mx-auto">
                    <h2 class="text-white text-base font-semibold border-b-2 font-Poppins w-fit border-secondary mb-2 lg:mb-3">Contact</h2>
                    <div class="text-sm text-white font-Poppins">Jl Patimura 10 Batam</div>
                    <div class="text-white text-sm font-Poppins"><span><fa-icon class="fa-xs text-slate-50 mr-2" [icon]="faPhone"></fa-icon></span>+62 823-9259-0880</div>
                </div>
                
            </div>
            <hr class="mx-auto max-w-6xl mt-10">
            <div class="text-white text-sm text-center py-3"><span><fa-icon class="fa-sm" [icon]="faCopyright"></fa-icon></span>copyright <a class="font-bold" href="https://folxcode.com/">Folxcode</a> 2022.All Right Reserved</div>
        </div>
    </div>
</div>