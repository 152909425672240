import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { MediaDetailComponent } from './pages/media/media-detail/media-detail.component';
import { MediaComponent } from './pages/media/media.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'media',
    component: MediaComponent
  },
  {
    path: 'mediadetail/:slug',
    component: MediaDetailComponent
  },
  {
    path: '', redirectTo: '/', pathMatch: 'full'
  },
  {
    path: '**', component: HomeComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
