import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MediaComponent } from './pages/media/media.component';
import { MediaDetailComponent } from './pages/media/media-detail/media-detail.component';
import { HomeComponent } from './pages/home/home.component';
import { SwiperModule } from 'swiper/angular'

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    MediaComponent,
    HomeComponent,
    MediaDetailComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    RouterModule,
    FontAwesomeModule,
    SwiperModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
