import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import media from 'src/assets/media.json'

@Component({
  selector: 'app-media-detail',
  templateUrl: './media-detail.component.html',
  styleUrls: ['./media-detail.component.css']
})
export class MediaDetailComponent implements OnInit {
 mediaPages:any = []
  mediaPage: any = {};
  mediaSlug!:string;
  
  constructor(private route: ActivatedRoute, private metaService: Meta, private title: Title) {
    this.mediaSlug = String(this.route.snapshot.paramMap.get('slug'))
   }

  ngOnInit(): void {
    this.loadData();
  }
  loadData(){
    this.mediaPages = media;
    this.mediaPages.map((media:any)=>{
      if (media.slug == this.mediaSlug){
        this.mediaPage = media;
      }
    })
    this.updateMetaTags()
     
  };
   //Update Meta Tag
   updateMetaTags() {
    this.metaService.updateTag({name:'title', content:this.mediaPage.title});
    this.metaService.updateTag({name:'description', content:this.mediaPage.content});
 
    this.metaService.updateTag({property:'og:title', content:this.mediaPage.title});
    this.metaService.updateTag({property:'og:description', content:this.mediaPage.content});
    this.metaService.updateTag({property:'og:image', content:this.mediaPage.img});
   }
   
}
